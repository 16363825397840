import { useRoute, computed, useContext } from '@nuxtjs/composition-api';

export default function useCurrentPage() {
  // TODO: Implement other isWHICHPage depending on usage
  const { i18n } = useContext();
  const route = useRoute();

  const isHomePage = computed(() => {
    const locale = i18n.locale;
    const path = route.value.path;
    return (
      path === `/${locale}/` ||
        path === `/${locale}` ||
        path === '/' ||
        path === ''
    );
  });
  return {isHomePage};
}
